//@ts-nocheck

import {
  Space,
  Button,
  Form,
  message,
  Row,
  DatePicker,
  Tabs,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import { PAYMENT_DATE_FORMAT } from "../../CONSTANTS/formats";
import { getStatisticsByDate, getUsers } from "../api/db-api";
import { useEffect, useState } from "react";
import { getColumns, getTableSummary } from "./columns";

const { RangePicker } = DatePicker;

export default function StatisticsByDate() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState();
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const usersFromDb = await getUsers();
      const users = usersFromDb.map((userToSelect, index: number) => {
        userToSelect.label = userToSelect.login;
        userToSelect.value = userToSelect.login;
        userToSelect.key = index + 1;
        return userToSelect;
      });
      setUsers(users);
    };
    getData();
  }, []);

  const onFinish = async (values: any) => {
    const from = dayjs(values.period[0]).format(PAYMENT_DATE_FORMAT);
    const to = dayjs(values.period[1]).format(PAYMENT_DATE_FORMAT);
    const user = values.user;

    const response = await getStatisticsByDate(from, to, user);
    if (response.status === "error") return message.error(response.message);

    const statisctics = response.data;

    setStatisticsData(statisctics);
  };

  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        layout="horizontal"
        name="getPaymentForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ justifyContent: "center" }}
        form={form}
      >
        <Row>
          <Space style={{ gap: "20px" }}>
            {/* Встановлюємо відстань між елементами */}
            <Form.Item
              label="Період"
              name="period"
              rules={[
                {
                  required: true,
                  message: "Виберіть діапазон дат!",
                },
              ]}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item
              label="Користувач"
              name="user"
              rules={[
                {
                  required: true,
                  message: "Please select a user!",
                },
              ]}
            >
              <Select
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={users}
                style={{ width: "200px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Отримати
              </Button>
            </Form.Item>
          </Space>
        </Row>
      </Form>
      <Table
        scroll={{
          y: window.screen.height - window.screen.height / 3,
        }}
        summary={() => getTableSummary(statisticsData)}
        // loading={loading}
        // onRow={onRowAction}
        columns={getColumns()}
        dataSource={statisticsData}
        bordered={true}
        pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
      />
    </div>
  );
}
