//@ts-nocheck

import { message, Popconfirm, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getDataFromServer, removeCompany } from "../components/api/db-api";
import AddCompanyForm from "../components/companies/AddCompanyForm";

export default function Companies({ user }) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getData();
  }, [user]);

  const getData = async () => {
    const response = await getDataFromServer("get-waapp-companies");
    if (response.status === "error") return message.error(response.message);

    const companiesFromDb = response.data;
    const companies = companiesFromDb.map((company, index) => {
      company.key = index + 1;
      return company;
    });

    setCompanies(companies);
  };

  const columns = [
    {
      title: "№",
      key: "№",
      dataIndex: "key",
    },
    {
      title: "Компанія",
      dataIndex: "company",
      key: "company",
      align: "center",
      render: (_, record) => record.name,
    },
    {
      title: "Інформація",
      dataIndex: "info",
      key: "info",
      align: "center",
    },
    {
      title: "Видалити",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record)}
        >
          <a>Видалити</a>
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = async (record) => {
    const response = await removeCompany(record.company.id);
    if (response.status === "error") return message.error(response.message);

    const newData = companies.filter((item) => item.key !== record.key);
    setCompanies(newData);
    message.success(response.message);
  };

  const items = [
    {
      key: "1",
      label: `Компанії`,
      children: <Table columns={columns} dataSource={companies}></Table>,
    },
    {
      key: "2",
      label: `Додати компанію`,
      children: (
        <AddCompanyForm
          companies={companies}
          setCompanies={setCompanies}
        ></AddCompanyForm>
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
    </div>
  );
}
