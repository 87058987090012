//@ts-nocheck

import { RecordActionsMenu } from "../RecordActionsMenu";

export const getColumns = (
  handleDelete,
  setSelectedPay,
  setIsModalEditPaymentOpen
) => [
  {
    title: "№",
    key: "№",
    dataIndex: "key",
  },
  {
    title: "Компанія",
    dataIndex: "company",
    key: "company",
    align: "center",
    render: (_, record) => record.company.name,
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    align: "center",

    sorter: (
      a: { date: string | number | Date },
      b: { date: string | number | Date }
    ) => new Date(a.date) - new Date(b.date),
  },
  {
    title: "Оплата",
    dataIndex: "sum",
    key: "sum",
    align: "center",
    sorter: (a: { sum: number }, b: { sum: number }) => a.sum - b.sum,
    defaultSortOrder: "descend",
  },
  {
    title: "Сума",
    dataIndex: "sum",
    key: "sum",
    align: "center",
    sorter: (a: { sum: number }, b: { sum: number }) => a.sum - b.sum,
    render: (_, record) =>
      record.companyName === "Продажа Техніки" ? record.sum : record.sum / 2,
  },
  {
    title: "Оплачено до",
    dataIndex: "paymentDueDate",
    key: "paymentDueDate",
    align: "center",
  },
  {
    title: "Коментар",
    dataIndex: "comment",
    key: "comment",
    align: "center",
  },
  {
    title: "Дії",
    key: "actions",
    render: (_, record) => {
      return (
        <RecordActionsMenu
          record={record}
          onEdit={(record) => {
            setSelectedPay(record);
            setIsModalEditPaymentOpen(true);
          }}
          onDelete={(record) => {
            handleDelete(record);
          }}
        />
      );
    },
  },
];
