//@ts-nocheck

import { Tabs } from "antd";
import UpsertPaymentForm from "./UpsertPaymentForm";

function UpsertPayment({
  companies,
  taCompanies,
  payments,
  setPayments,
  isTaPayment,
}) {
  const tabItems = [
    {
      key: "1",
      label: `WAAPP`,
      children: (
        <UpsertPaymentForm
          payments={payments}
          setPayments={setPayments}
          companies={companies}
          isTaPayment={false}
        ></UpsertPaymentForm>
      ),
    },
    {
      key: "2",
      label: `TA`,
      children: (
        <UpsertPaymentForm
          payments={payments}
          setPayments={setPayments}
          companies={taCompanies}
          isTaPayment={true}
        ></UpsertPaymentForm>
      ),
    },
  ];

  return (
    <div>
      <Tabs tabPosition={"left"} items={tabItems} />
    </div>
  );
}

export default UpsertPayment;
