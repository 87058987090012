//@ts-nocheck

import { message, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getDataFromServer } from "../components/api/db-api";
import ExpectedPayments from "../components/payments/ExpectedPayments";
import TaPayments from "../components/payments/TA/TaPayments";
import UpsertPayment from "../components/payments/upsert/UpsertPayment";
import WaappPayments from "../components/payments/WaappPayments";

export default function Payments({ user }) {
  const [companies, setCompanies] = useState([]);
  const [taCompanies, setTaCompanies] = useState([]);

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    getData();
  }, [user]);

  const getData = async () => {
    const response = await getDataFromServer("get-waapp-companies");
    if (response.status === "error") return message.error(response.message);

    const companiesFromDb = response.data;
    const companies = companiesFromDb.map((company, index) => {
      company.key = index;
      company.label = company.name;
      company.value = company.id;
      return company;
    });

    setCompanies(companies);

    const responseTa = await getDataFromServer("get-ta-waapp-companies");
    if (responseTa.status === "error") return message.error(responseTa.message);

    const taCompaniesFromDb = responseTa.data;
    const taCompanies = taCompaniesFromDb.map((company, index) => {
      company.key = index;
      company.label = company.name;
      company.value = company.id;
      return company;
    });

    setTaCompanies(taCompanies);
  };

  const items = [
    {
      key: "1",
      label: `Оплати`,
      children: (
        <>
          <WaappPayments user={user} companies={companies}></WaappPayments>
          <br></br>
          <TaPayments user={user} companies={taCompanies}></TaPayments>
        </>
      ),
    },
    {
      key: "2",
      label: `Додати оплату`,
      children: (
        <UpsertPayment
          payments={payments}
          setPayments={setPayments}
          companies={companies}
          taCompanies={taCompanies}
        ></UpsertPayment>
      ),
    },
    {
      key: "3",
      label: `Очікувані оплати`,
      children: <ExpectedPayments></ExpectedPayments>,
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
    </div>
  );
}
