import { Modal, Tabs } from "antd";
import CreateForm from "../users/CreateForm";

interface ModalCreateUserProps {
  isModalCreateUserOpen: boolean;
  setIsModalCreateUserOpen: Function;
  setUsers: Function;
}

export default function ModalCreateUser({
  isModalCreateUserOpen,
  setIsModalCreateUserOpen,
  setUsers,
}: ModalCreateUserProps) {
  const handleCancel = () => {
    setIsModalCreateUserOpen(false);
  };

  const items = [
    {
      key: "1",
      label: `Дані`,
      children: (
        <CreateForm
          setUsers={setUsers}
          setIsModalCreateUserOpen={setIsModalCreateUserOpen}
        ></CreateForm>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={"Створення нового користувача"}
        open={isModalCreateUserOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={handleCancel}
        width={"90%"}
      >
        <Tabs defaultActiveKey="1" items={items}></Tabs>
      </Modal>
    </div>
  );
}
