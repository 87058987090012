import { Table } from "antd";

export const getTableSummary = (payments) => {
  let totalAmount = 0;
  let totalPay = 0;
  payments.forEach(({ sum, companyName }) => {
    totalPay += companyName === "Продажа Техніки" ? +sum : +sum / 2;
    totalAmount += +sum;
  });

  return (
    <>
      <Table.Summary.Row
        style={{
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Загалом: ${totalAmount} грн`}</Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Сума: ${totalPay} грн`}</Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
