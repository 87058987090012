import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "../UI/css/Loginpage.css";
import { login, verifyCode } from "../components/api/db-api";
import { useAuth } from "../hook/useAuth";

const Loginpage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isWaitingEnterCode, setIsWaitingEnterCode] = useState(false);
  const [timeLeft, setTimeLeft] = useState<any>();
  const [tempUserLogin, setTempUserLogin] = useState("");
  const { user, signIn } = useAuth();

  useEffect(() => {
    if (!tempUserLogin) return;
    const interval = setInterval(() => {
      setTimeLeft((prevTime: number) => prevTime - 1); // Зменшуємо час на 1 кожну секунду
    }, 1000);
    // Якщо час вичерпано, зупиняємо інтервал
    if (timeLeft === 0) {
      clearInterval(interval);
      setIsWaitingEnterCode(false);
    }
    // Очищаємо інтервал, коли компонент буде демонтований
    return () => clearInterval(interval);
  }, [timeLeft, tempUserLogin]);

  if (user) {
    message.success("Ви вже авторизовані!");
    return <Navigate to="/"></Navigate>;
  }
  const fromPage = location.state?.from?.pathname || "/";

  // Форматуємо час у вигляді MM:SS
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const timeFormatted = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  const onFinish = async (values) => {
    const username = values.username;
    const password = values.password;
    try {
      const result = await login(username, password);

      if (result.status === "success") {
        setIsWaitingEnterCode(true);
        setTempUserLogin(username);
        setTimeLeft(60);
        // setTimeout(() => {
        //   if (isWaitingEnterCode) {
        //     setIsWaitingEnterCode(false);
        //     return message.info("Час очікування вийшов!");
        //   }
        // }, 6000);
      } else {
        return message.error(result.message);
      }
    } catch (error) {
      return message.error("Невідома помилка...");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishEnterCode = async (values) => {
    const response = await verifyCode(values.code, tempUserLogin);
    if (response.status === "error") return message.error(response.message);

    setIsWaitingEnterCode(false);

    const user = { username: "admin" };
    sessionStorage.setItem("user", JSON.stringify(user));
    message.success("Cвій чоловік");
    return signIn(user, () => navigate(fromPage, { replace: true }));
  };

  return (
    <>
      <div className="loginPage">
        {isWaitingEnterCode && (
          <>
            <Form
              className="loginForm"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishEnterCode}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div>
                <h3>{timeFormatted}</h3>
              </div>
              <Form.Item
                label="Код"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Введіть логін!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item>
                <Button size="large" block type="primary" htmlType="submit">
                  Йо
                </Button>
              </Form.Item>
            </Form>
          </>
        )}

        {!isWaitingEnterCode && (
          <Form
            className="loginForm"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Логін"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Введіть логін!",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Введіть пароль!",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Button size="large" block type="primary" htmlType="submit">
                Вхід
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export default Loginpage;
