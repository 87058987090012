//@ts-nocheck

import { Button, DatePicker, Form, Input, message, Select } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { addCompanyPayment } from "../../api/payments/functions";

export default function UpsertPaymentForm({
  payments,
  setPayments,
  companies,
  selectedPay = null,
  setIsModalOpen = null,
  isTaPayment,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedPay) {
      form.setFieldsValue({
        date: dayjs(selectedPay.date),
        paymentDueDate: dayjs(selectedPay.paymentDueDate),
        sum: selectedPay.sum,
        company: String(selectedPay.company.id),
        comment: selectedPay.comment,
        paymentId: selectedPay.paymentId,
      });
    }
  }, [selectedPay, form]);

  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    const added = await addCompanyPayment(
      values.company,
      +values.sum,
      values.date.add(1, "day"),
      values.paymentDueDate.add(1, "day"),
      values.comment,
      values.paymentId,
      isTaPayment
    );
    if (added.status === "error")
      return message.error("Помилка додавання оплати");
    message.success(added.message);
    const selectedCompany = companies.find(
      (comp) => comp.id === values.company
    );

    const newRecord = {
      key: payments.length + 1,
      sum: +values.sum,
      date: values.date.format(PAYMENT_DATE_FORMAT),
      paymentDueDate: values.paymentDueDate.format(PAYMENT_DATE_FORMAT),
      comment: values.comment,
      company: {
        id: selectedCompany.id,
        name: selectedCompany.name,
      },
      paymentId: selectedPay && selectedPay.paymentId,
    };

    if (selectedPay) {
      const { key, ...newRecordWithoutKey } = newRecord;

      setIsModalOpen(false);
      return setPayments((prevPayments) => {
        return prevPayments
          .map((pay) => {
            // Припустимо, що 'newRecord' містить 'paymentId' платіжного запису
            if (pay.paymentId === newRecord.paymentId) {
              // Якщо знайшли відповідний запис, повертаємо новий запис
              return { ...pay, ...newRecordWithoutKey };
            }
            // Інакше повертаємо без змін
            return pay;
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date));
      });
    }

    setPayments((prevPayments) => {
      return [...prevPayments, newRecord].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    });
  };

  return (
    <div>
      <Form
        layout="vertical"
        name="paymentForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        style={{
          width: "75%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Form.Item
          label="Сума"
          name="sum"
          rules={[
            {
              required: true,
              message: "Please input the amount!",
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Компанія"
          name="company"
          rules={[
            {
              required: true,
              message: "Please select a company!",
            },
          ]}
          style={{ width: "100%" }}
        >
          <Select options={companies} />
        </Form.Item>

        <Form.Item
          label="Дата оплати"
          name="date"
          rules={[
            {
              required: true,
              message: "Please select the payment date!",
            },
          ]}
          style={{ width: "100%" }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Оплачено до"
          name="paymentDueDate"
          rules={[
            {
              required: true,
              message: "Please select the payment due date!",
            },
          ]}
          style={{ width: "100%" }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Коментар"
          name="comment"
          rules={[
            {
              required: false,
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Ід"
          name="paymentId"
          rules={[
            {
              required: false,
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item style={{ width: "100%", textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            {selectedPay ? "Оновити" : "Додати"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
