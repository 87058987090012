//@ts-nocheck
// компонент не використовується бо зробив один універсальний для всіх сервісів

import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, message } from "antd";
import { updateUsersSetting } from "../../api/db-api";
import { getServiceSettingsObject } from "./functions";

export default function RevisionSettings({ settings, users }) {
  const [showRealCount, setShowRealCount] = useState(false);
  const [exportRealCount, setExportRealCount] = useState(false);
  const [showSumByDiff, setShowSumByDiff] = useState(false);
  const [showArtInTable, setShowArtInTable] = useState(false);
  const [color_differents, setColor_differents] = useState(false);
  const [additionalHighlightDifferents, setAdditionalHighlightDifferents] =
    useState(false);

  const CHECKBOX_TO_SETTER = {
    showRealCount: setShowRealCount,
    exportRealCount: setExportRealCount,
    showSumByDiff: setShowSumByDiff,
    showArtInTable: setShowArtInTable,
    color_differents: setColor_differents,
    additionalHighlightDifferents: setAdditionalHighlightDifferents,
  };

  useEffect(() => {
    if (settings) {
      setSettingsData();
    }
  }, []);

  const setSettingsData = async () => {
    const settingsObj = getServiceSettingsObject(settings, "revizia");

    setShowRealCount(settingsObj.showRealCount);
    setExportRealCount(settingsObj.exportRealCount);
    setShowSumByDiff(settingsObj.showSumByDiff);
    setShowArtInTable(settingsObj.showArtInTable);
    setColor_differents(settingsObj.color_differents);
    setAdditionalHighlightDifferents(settingsObj.additionalHighlightDifferents);
  };

  const onChangeSetting = async (e) => {
    const sett = {
      service: e.target.service,
      name: e.target.name,
      value: e.target.checked,
    };

    const result = await updateUsersSetting(
      users.map((user) => user.id),
      sett
    );

    const resultObj = result.reduce((acc, cur) => ({ ...acc, ...cur }), {});
    const updateStatus = resultObj.updateStatus;
    const addStatus = resultObj.addStatus;

    if (updateStatus?.status === "error" || addStatus?.status === "error") {
      return message.error("Помилка при зміні налаштувань!");
    }

    if (updateStatus?.status) {
      message.info(updateStatus?.message);
    }
    if (addStatus?.status) {
      message.info(addStatus?.message);
    }

    const setter = CHECKBOX_TO_SETTER[sett.name];
    setter(sett.value);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="exportRealCount"
            checked={exportRealCount}
            onChange={onChangeSetting}
          >
            Вигружати облікову кількість
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="showSumByDiff"
            checked={showSumByDiff}
            onChange={onChangeSetting}
          >
            Показувати суму документа по розбіжності
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="showArtInTable"
            checked={showArtInTable}
            onChange={onChangeSetting}
          >
            Показувати артикул
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="color_differents"
            checked={color_differents}
            onChange={onChangeSetting}
          >
            Підсвітка розбіжностей
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="additionalHighlightDifferents"
            checked={additionalHighlightDifferents}
            onChange={onChangeSetting}
          >
            Додаткова підсвітка розбіжностей
          </Checkbox>
        </Col>
        <Col span={24}>
          <Checkbox
            service="revizia"
            name="showRealCount"
            checked={showRealCount}
            onChange={onChangeSetting}
          >
            Показувати облікову кількість
          </Checkbox>
        </Col>
      </Row>
    </>
  );
}
