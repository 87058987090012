//@ts-nocheck

import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { PAYMENT_DATE_FORMAT } from "../../../CONSTANTS/formats";
import { getTaPayments } from "../../api/db-api";
import { removePayment } from "../../api/payments/functions";
import ModalEdit from "../ModalEdit";
import { getColumns } from "../tables/Columns";
import { getTableSummary } from "../tables/TableSummary";
const { RangePicker } = DatePicker;

export default function TaPayments({ user, companies }) {
  const [payments, setPayments] = useState([]);
  const [isModalEditPaymentOpen, setIsModalEditPaymentOpen] = useState(false);
  const [selectedPay, setSelectedPay] = useState();
  const [form] = Form.useForm();

  const handleDelete = async (record) => {
    const response = await removePayment(
      record.paymentId,
      record.company.id,
      true
    );
    if (response.status === "error") return message.error(response.message);

    const newData = payments.filter((item) => item.key !== record.key);
    setPayments(newData);
    message.success(response.message);
  };

  const onFinish = async (values: any) => {
    const companyId = values.companyId;
    const from = values.period?.[0]
      ? dayjs(values.period[0]).format(PAYMENT_DATE_FORMAT)
      : null;
    const to = values.period?.[1]
      ? dayjs(values.period[1]).format(PAYMENT_DATE_FORMAT)
      : null;

    if (!companyId && !from && !to) {
      return message.error("Виберіть компанію або період");
    }

    const responsePayments = await getTaPayments(from, to, companyId);
    if (responsePayments.status === "error")
      return message.error(responsePayments.message);

    const payments = responsePayments.data.sort((a, b) => a.sum - b.sum);

    const paymentsToTable = payments.map((pay, key) => {
      return {
        ...pay,
        key: key + 1,
        company: { id: pay.companyId, name: pay.companyName },
      };
    });
    setPayments(paymentsToTable);
  };
  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h2>Торгові</h2>
      <Form
        layout="horizontal"
        name="getPaymentForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Space size="large">
          <Row align="middle" gutter={16} justify="start">
            <Col>
              <Form.Item label="Період" name="period">
                <RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Компанія"
                name="companyId"
                rules={[
                  {
                    required: false,
                    message: "Please select a company!",
                  },
                ]}
                style={{ width: "300px" }}
              >
                <Select
                  options={companies}
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="label"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Отримати
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>

      <Table
        columns={getColumns(
          handleDelete,
          setSelectedPay,
          setIsModalEditPaymentOpen
        )}
        dataSource={payments}
        summary={() => getTableSummary(payments)}
        pagination={{ pageSize: 999, hideOnSinglePage: true }}
      ></Table>
      <ModalEdit
        payments={payments}
        setPayments={setPayments}
        companies={companies}
        isModalOpen={isModalEditPaymentOpen}
        setIsModalOpen={setIsModalEditPaymentOpen}
        selectedPay={selectedPay}
      ></ModalEdit>
    </div>
  );
}
