//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Space,
  message,
  Form,
  Button,
  Divider,
  Checkbox,
} from "antd";
import RevisionSettings from "./services-settings/RevisionSettings";
import {
  getServiceSettingsArray,
  getServiceSettingsObject,
} from "./services-settings/functions";
import {
  editUserFtp,
  saveWeightTemplate,
  updateUserSetting,
} from "../api/db-api";
import OrdersSettings from "./services-settings/OrdersSettings";
import { serviceTitlesInSettings } from "../../CONSTANTS/serviceTitles";
import VerificationSettings from "./services-settings/VerificationSettings";
const { Search } = Input;

interface SettingsFormProps {
  user: User;
  setUsers: SetUsers;
}

export default function SettingsForm({ user, setUsers }: SettingsFormProps) {
  const [form2] = Form.useForm();
  const settingsObj = getServiceSettingsObject(user?.settings, "global");

  const [useCharacteristics, setUseCharacteristics] = useState(
    settingsObj?.useCharacteristics
  );

  const [useEnteringExpirationDates, setUseEnteringExpirationDates] = useState(
    settingsObj?.useEnteringExpirationDates
  );

  const CHECKBOX_TO_SETTER = {
    useCharacteristics: setUseCharacteristics,
    useEnteringExpirationDates: setUseEnteringExpirationDates,
  };

  useEffect(() => {
    form2.setFieldsValue({
      ftpAddress: user.ftpAddress ? user.ftpAddress : "",
      ftpUser: user.ftpUser ? user.ftpUser : "",
    });
  }, [user]);

  const onChangeCharacteristicsSetting = async (e) => {
    const value = e.target.checked;

    const sett = {
      service: e.target.service,
      name: e.target.name,
      value,
    };

    const setter = CHECKBOX_TO_SETTER[sett.name];
    setter(sett.value);
    const success = await updateUserSetting(user.id, sett);
    message.info(success.message);
  };

  const onFinish = async (values) => {
    const result = await editUserFtp(values, user.id);

    if (result?.status === "success") {
      setUsers((prevUsers) => {
        return prevUsers.map((prevUser) =>
          prevUser.id === user.id
            ? {
                ...prevUser,
                ownFtp: true,
              }
            : prevUser
        );
      });
      message.success("Успішно!");
    } else {
      message.error("Помилка оновлення користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const servicesItems = [
    {
      key: "1",
      label: `Ревізія`,
      children: (
        <RevisionSettings
          users={[user]}
          settings={getServiceSettingsArray(user.settings, "revizia")}
        ></RevisionSettings>
      ),
    },
    {
      key: "2",
      label: `Замовлення`,
      children: (
        <OrdersSettings
          users={[user]}
          settings={getServiceSettingsArray(user.settings, "orders")}
        ></OrdersSettings>
      ),
    },
    {
      key: "3",
      label: `Перевірка документів`,
      children: (
        <VerificationSettings
          users={[user]}
          settings={getServiceSettingsArray(
            user.settings,
            serviceTitlesInSettings.VERIFICATION
          )}
        ></VerificationSettings>
      ),
    },
  ];

  const saveTemplate = async (value) => {
    if (!value) {
      return message.error("Введіть шаблон!");
    }
    const result = await saveWeightTemplate(user.id, value);
    if (result) {
      return message.success("Збережено");
    }
    return message.error("Помилка!");
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Space direction="vertical">
            <strong>
              Шаблон штрихкоду для вагового товару Приклад: 21QQQQQPPPPP (21 -
              префікс, PPPPP - код товару, QQQQQ - вага товару)
            </strong>
            <Search
              defaultValue={user.weightTemplate}
              enterButton="Зберегти"
              allowClear
              onSearch={saveTemplate}
              style={{ width: "50%" }}
            />
          </Space>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Space>
          <Checkbox
            service="global"
            name="useCharacteristics"
            checked={useCharacteristics}
            onChange={onChangeCharacteristicsSetting}
          >
            Використовувати характеристики
          </Checkbox>
        </Space>
        <Space>
          <Checkbox
            service="global"
            name="useEnteringExpirationDates"
            checked={useEnteringExpirationDates}
            onChange={onChangeCharacteristicsSetting}
          >
            Запитувати введення терміну придатності (характеристики)
          </Checkbox>
        </Space>
      </Row>
      <Row>
        <Tabs
          defaultActiveKey="1"
          items={servicesItems}
          // onChange={onChange}
        ></Tabs>
      </Row>
      <Divider></Divider>
      <strong>Власне FTP</strong>
      <br></br>
      <br></br>
      <Row>
        <Col>
          <Form
            layout="vertical"
            name="settingsForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ justifyContent: "center" }}
            form={form2}
          >
            <Space direction="vertical>">
              <Form.Item
                label="FTP адреса:порт"
                name="ftpAddress"
                rules={[
                  {
                    required: false,
                    message: "Please input your ftp address!",
                  },
                ]}
              >
                <Input allowClear={true} />
              </Form.Item>
              <Form.Item
                label="FTP користувач"
                name="ftpUser"
                rules={[
                  {
                    required: false,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input allowClear={true} />
              </Form.Item>

              <Form.Item
                label="FTP пароль"
                name="ftpPassword"
                rules={[
                  {
                    required: false,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Зберегти
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
