import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createUser, getCompanies } from "../api/db-api";
import { createFtpUser } from "../api/hosting-api/functions";
import { Company } from "../interfaces/company.interface";
import { CreateWaappUserDto } from "../interfaces/create-waapp-user.interface";

const containsSpecialChars = (str: string) => {
  const regex = /[.-]/; // регулярний вираз для перевірки на . або -
  return regex.test(str);
};

export default function CreateForm({
  setUsers,
  setIsModalCreateUserOpen,
}: any) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isCheckedCreateFtp, setIsCheckedCreateFtp] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesFromDb = await getCompanies();
      const companiesToSelect = companiesFromDb.map(
        (company: Company, index: number) => {
          return {
            key: index,
            label: company.name,
            value: company.id,
          };
        }
      );

      setCompanies(companiesToSelect);
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ deviceCount: 1, useFtp: true });
  }, [form]);

  const onFinish = async (values: any) => {
    if (containsSpecialChars(values.login)) {
      return message.error("Недопустимі символи у імені користувача");
    }

    if (isCheckedCreateFtp) {
      const ftpLogin = values.login;
      const ftpPassword = values.password;
      const homedir = `waapp.com.ua/app/app-waapp-users/${ftpLogin}/`;
      const result = await createFtpUser(ftpLogin, ftpPassword, homedir);
      if (!result.result) {
        return message.error(result.messages.error);
      }
      message.success(result.messages.success);
    }

    const dto: CreateWaappUserDto = {
      login: values.login,
      password: values.password,
      ipAddress: values.ipAddress,
      expirationDate: values.expirationDate,
      roles: values.roles,
      exchangeType: values.exchangeType,
      createFtp: isCheckedCreateFtp,
      company: values.company.label,
      deviceCount: values.deviceCount,
    };

    const result = await createUser(dto);

    if (result?.status === "success") {
      message.success("Успішно!");
      navigate("/", { state: { updateTable: true } });
    } else {
      message.error("Помилка додавання користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <Row justify={"center"}>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="Логін"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Компанія"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Виберіть компанію",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={companies}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Іп адреса:порт"
                name="ipAddress"
                rules={[
                  {
                    required: false,
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="expirationDate"
                label="Доступ до"
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="roles" label="Доступні ролі">
                <Checkbox.Group>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="inventory">Ревізія</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="invoice">Прихід / Повернення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="perem">Переміщення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="order">Замовлення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="sales">Реалізація</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="checkPrice">Перевірка цін</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="barcoding">Штрихкодування</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="exchangeType">
                <Radio.Group>
                  <Radio value={"xml"} defaultChecked={true}>
                    XML
                  </Radio>
                  <Radio value={"txt"}>TXT</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name={"createFtp"}>
                <Checkbox
                  checked={isCheckedCreateFtp}
                  onChange={() => {
                    setIsCheckedCreateFtp(!isCheckedCreateFtp);
                  }}
                >
                  Створити FTP акаунт (Ukraine Hosting)
                </Checkbox>
              </Form.Item>
              <Form.Item name="deviceCount" label="К-ть пристроїв">
                <InputNumber min={1} defaultValue={1}></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Створити
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}
