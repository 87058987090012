import { message } from "antd";
import { getServer } from "../../utils/Environment";

const server = getServer();

export const createFtpUser = async (login, password, homedir) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "create-ftp-user",
      login,
      password,
      homedir,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    console.log(error);
    return false;
  }
};

export async function getFtpUserAccountByUsername(username: string) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "get-ftp-user-account-by-username",
      username,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    message.error(error);
    return false;
  }
}

export async function editFtpUser(login, password, homedir, ftpUserId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "edit-ftp-user",
      login,
      password,
      homedir,
      ftpUserId,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    message.error(error);
    return false;
  }
}

export async function checkBlockIp(ip) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "check-block-ip",
      ip,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    message.error(error);
    return false;
  }
}

export async function unblockIp(ip) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "unblock-ip",
      ip,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    message.error(error);
    return false;
  }
}
