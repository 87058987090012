//@ts-nocheck

import React from "react";
import RevisionSettings from "../services-settings/RevisionSettings";
import { Row, Col, Tabs, Input, Space, message } from "antd";
import { saveWeightTemplate } from "../../api/db-api";
const { Search } = Input;

export default function MultipleEditSettingsForm({ users }) {
  const servicesItems = [
    {
      key: "1",
      label: `Ревізія`,
      children: <RevisionSettings users={users}></RevisionSettings>,
    },
  ];

  const saveTemplate = async (value) => {
    if (!value) {
      return message.error("Введіть шаблон!");
    }
    users.forEach(async (user) => {
      const result = await saveWeightTemplate(user.id, value);
      if (result) {
        return message.success("Збережено");
      }
      return message.error("Помилка!");
    });
  };

  return (
    <>
      <Row>
        <Space>
          <strong>
            Шаблон штрихкоду для вагового товару Приклад: 21QQQQQPPPPP (21 -
            префікс, PPPPP - код товару, QQQQQ - вага товару)
          </strong>
        </Space>
      </Row>

      <Col span={10}>
        <Search
          //   defaultValue={user.weightTemplate}
          enterButton="Зберегти"
          allowClear
          onSearch={saveTemplate}
        />
      </Col>
      <Row>
        <Tabs
          defaultActiveKey="1"
          items={servicesItems}
          // onChange={onChange}
        ></Tabs>
      </Row>
    </>
  );
}
